import vueFormGenerator from 'vue-form-generator';

export const model = {
	AcompanyId: 0,
	AcompanyName: '',
	AcompanyStreet: '',
	AcompanyCity: '',
	CountryId: null,
	AcompanyZip: '',
	AcompanyIco: '',
	AcompanyDic: '',
	AcompanyIcdph: '',
	AcompanyPhone: '',
	AcompanyEmail: '',
	AcompanyBankName: '',
	AcompanyBankAddress: '',
	AcompanyBankCode: '',
	AcompanyBankAccount: '',
	AcompanyBankIban: '',
	AcompanyBankSwift: '',
	AcompanyRegistrator: '',
	AcompanyInvoiceBottomText: '',
	AcompanyInvoiceType: null,
	AcompanyVatPerc: 0,
	AcompanySiteName: '',
	AcompanySiteDomain: '',
	AcompanySiteEmail: '',
	AcompanyFacebook: '',
	AcompanySignatureAdmin: '',
	AcompanySignatureTeam: '',
	AcompanySignatureOperator: '',
	AcompanyTermofuse: '',
	AcompanyPrivacy: '',
	AcompanyAbout: '',
	AcompanyIsUsed: false,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyName',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'emailtype',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyStreet',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'street',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyCity',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'city2709',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyZip',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'zip',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'select',
				inputType: 'text',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'Id',
					name: 'Name',
				},
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyIco',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'companyid',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyDic',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'taxid',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyIcdph',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'vatid',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyPhone',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'phoneonthesite',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyEmail',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'emailonwebsite',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyBankName',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'bank',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyBankAddress',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'bankaddress',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyBankCode',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'bankcode3051',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyBankAccount',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'bankaccount3052',
				},
				styleClasses: 'half-width',
			},

			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyIban',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'iban',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanySwift',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'swift',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyRegistrator',
				i18n: {
					label: 'companyregistra',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyInvoiceBottomText',
				i18n: {
					label: 'registrationinf',
				},
			},
			{
				type: 'select',
				inputType: 'text',
				model: 'AcompanyInvoiceType',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					value: 'id',
					name: 'name',
				},
				i18n: {
					label: 'typeofinvoices',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'AcompanyVatPerc',
				required: true,
				validator: vueFormGenerator.validators.integer,
				i18n: {
					label: 'vatamount',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanySiteName',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'sitename',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanySiteDomain',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'sitedomain',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanySiteEmail',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'siteemail',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AcompanyFacebook',
				i18n: {
					label: 'facebook',
				},
			},
			{
				type: 'html-text-editor',
				model: 'AcompanySignatureAdmin',
				i18n: {
					label: 'adminsignature',
				},
			},
			{
				type: 'html-text-editor',
				model: 'AcompanySignatureTeam',
				i18n: {
					label: 'teamsignature',
				},
			},
			{
				type: 'html-text-editor',
				model: 'AcompanySignatureOperator',
				i18n: {
					label: 'operatorsignatu',
				},
			},
			{
				type: 'html-text-editor',
				model: 'AcompanyTermofuse',
				i18n: {
					label: 'termsofuse',
				},
			},
			{
				type: 'html-text-editor',
				model: 'AcompanyPrivacy',
				i18n: {
					label: 'privacypolicy',
				},
			},
			{
				type: 'html-text-editor',
				model: 'AcompanyAbout',
				i18n: {
					label: 'freejobs',
				},
			},
			{
				type: 'switch',
				model: 'AcompanyIsUsed',
				i18n: {
					label: 'currentlyactive',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
		],
	},
];
