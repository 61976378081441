<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:headerTitle="headerTitle"
		tooltipMsg="manualforcompan3336"
		:showActionDelete="showDeleteButton"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	></detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceCommon from '@/services/service/common.service';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';
import serviceEnums from '@/services/service/enums.service';

import { model, fields } from './accounting-company.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'AccountingCompany',
			model,
			schema: {
				groups: fields,
			},
			routeParam: 'AcompanyId',
		};
	},

	computed: {
		headerTitle() {
			const title = this.$t('detailofcompany');
			const id = this.$t('id');
			const idNum = this.model.AcompanyId;
			return `${title} ${id} ${idNum}`;
		},

		showDeleteButton() {
			return auth.IsAllowedForUser(enums.role.Admin);
		},
	},

	methods: {
		async loadResources() {
			const CountryIdValues = this.$store.state.country.data;
			const AcompanyInvoiceTypeValues = serviceEnums.getEnumForVFG('InvoiceType');

			this.updateSchemaFields({
				CountryId: { values: CountryIdValues },
				AcompanyInvoiceType: { values: AcompanyInvoiceTypeValues },
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.AcompanyId, this.$modal, this.deleteModel);
		},
	},
};
</script>